@import "@styles/theme";

.root {
  z-index: 100;

  position: absolute;
  display: flex;
  flex-direction: row;
  top: 86px;
  left: 0;
  width: 100%;
  padding-left: 16px;
  padding-bottom: 16px;
  background-color: $color-white;
  box-shadow: 0px 20px 24px 0 rgba(0, 0, 0, 0.12);
  &.hide {
    opacity: 0;
    display: none;
    pointer-events: none;
  }
  &.show {
    -webkit-animation: fadeIn 0.4s;
    -moz-animation: fadeIn 0.4s;
    -o-animation: fadeIn 0.4s;
    animation: fadeIn 0.4s;
    top: 100%;
    z-index: 10;
    transition: top 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
  }
  .banner {
    flex: 4;
    display: flex;
    flex-direction: column;
    img {
      height: 100%;
      max-height: 250px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
    a {
      display: flex;
      flex-direction: row;

      padding: 1.2rem 0;
      text-decoration: none;
      color: $color-black;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    padding: 0 6.4rem;

    flex: 6;
    max-height: 240px;
    flex-wrap: wrap;

    align-content: space-around;
    width: 100%;
    a {
      padding: $spacing-md;
      display: flex;
      flex-direction: row;
      text-decoration: none;
      min-width: 50%;
      color: $color-black;
      &:hover {
        background-color: $color-secondary;
      }
      p {
        flex-grow: 1;
        padding: 0 $spacing-md;
      }
    }
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
