@import '@styles/theme';

.root {
  form {
    @include paddingVertical(16);
    @include paddingHorizontal(8);
    h2 {
      text-align: center;
      @include paddingVertical(16);
    }
    &.card {
      box-shadow: 1px 1px 24px 4px rgba(0, 0, 0, 0.08);
    }
    .distribuidorcontainer {
      @include column();
      align-items: center;
      .avatar {
        @include row();
        border-radius: 50%;
        overflow: hidden;
        border: 4px solid $color-primary;
        img {
          height: 112px;
          width: 112px;
        }
        margin-bottom: 1rem;
      }
    }
    @include column;
    @include marginVertical(16);
    font-family: $font-family-body;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    .fields {
      @include grid();
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      @include paddingHorizontal(16);
      @include for-size(mobile-portrait) {
        @include paddingHorizontal(8);
        grid-template-columns: 1fr;
      }
      @include for-size(mobile-landscape) {
        grid-template-columns: 1fr;
        @include paddingHorizontal(16);
      }

      .fieldcontainer {
        @include marginVertical(8);
        @include column();
        label {
          text-transform: uppercase;
          @include micro();
          @include paddingVertical(4);
        }
        input {
          &:focus {
            outline-style: solid;
            outline-width: 1px;
          }
          font-size: 1.6rem;
          border: 1px solid $color-gray100;
          @include paddingVertical(16);
          @include paddingHorizontal(24);
        }
        select {
          font-size: 1.6rem;
          border: 1px solid $color-gray100;
          @include paddingVertical(14);
          @include paddingHorizontal(24);
        }
        .cultivoslabel {
          text-transform: uppercase;
          letter-spacing: 1px;
          @include micro();
          text-transform: uppercase;
        }
        .cultivos {
          @include grid();
          grid-template-columns: 1fr 1fr;
          .cultivo {
            @include row();
            align-items: center;
            text-transform: none;
            svg {
              width: 18px;
              margin-left: 8px;
            }
            span,
            p {
              @include small();
              margin-left: 8px;
              font-family: $font-family-body;
            }
          }
        }
      }
    }

    .comentscontainer {
      @include paddingHorizontal(24);
      @include column();
      align-self: center;
      width: 100%;
      @include for-size(mobile-portrait) {
      }
      @include for-size(mobile-landscape) {
        width: 70%;
      }
      @include for-size(tablet-portrait) {
      }
      @include for-size(tablet-landscape) {
      }
      label {
        text-transform: uppercase;
        @include micro();
        @include paddingVertical(4);
        @include paddingHorizontal(4);
      }
      textarea {
        &:focus {
          outline-style: solid;
          outline-width: 1px;
        }
        border: 1px solid $color-gray100;
        @include normal();
        padding: 16px;
      }
    }
    .priv {
      @include row();
      a {
        color: $color-black;
      }
      align-self: center;
      align-items: center;
      justify-content: flex-start;
      text-transform: uppercase;
      @include paddingHorizontal(8);
      padding-top: 16px;
      padding-bottom: 8px;
      width: calc(100% - 16px);
      @include for-size(mobile-portrait) {
        max-width: 80%;
      }
      @include for-size(mobile-landscape) {
        max-width: 80%;
      }
      span,
      p {
        margin-left: 8px;
        text-align: center;
      }
    }
    .submitcontainer {
      div {
        text-align: center;
      }
      @include row();
      justify-content: center;
      button {
        background-color: $color-primary;
        color: $color-white;
        border: none;
        @include marginVertical(16);
        cursor: pointer;
        @include paddingVertical(12);
        @include paddingHorizontal(64);
        &:focus {
          outline: none;
        }
      }
    }
  }
  .dississedPanel {
    @include column();
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    @include marginVertical(16);
    padding: 32px;
    background-color: #e5f1d4;
    max-width: 340px;
  }
}
.textLabel {
  p {
    display: flex;
  }
  input {
    margin-right: 10px;
  }
}
.separacionTexto {
  margin-left: 5px;
}
