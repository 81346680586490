@import "@styles/theme";

.root {
  position: relative;
  @include row();
  width: 100%;
  align-items: center;
  background-color: $color-white;
  border-radius: 4px;

  @include for-size(mobile-portrait) {
  }
  @include for-size(mobile-landscape) {
  }
  @include for-size(tablet-portrait) {
  }
  @include for-size(tablet-landscape) {
  }
  &.card {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
  }
  .headerWrapper {
    @include paddingHorizontal(12);
    @include paddingVertical(14);
    width: 100%;
    background: none;
    outline: none;
    border: none;
    @include row();
    justify-content: flex-end;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .value {
      @include paddingHorizontal(12);
      font-weight: bold;
      .title {
        color: $color-black;
        opacity: 0.7;
      }
      .label {
        text-align: left;
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .toggleIcon {
      align-self: center;
      @include paddingHorizontal(8);
      svg {
        height: 16px;
        fill: $color-primary;
      }
    }
  }
  .list {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    border-radius: 4px;
    width: 100%;

    list-style: none;
    max-height: 400px;
    margin-top: 10px;
    overflow-y: scroll;
    top: 40px;
    @include for-size(mobile-portrait) {
      top: 40px;
    }
    right: 0;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    background-color: $color-white;
    li {
      @include paddingHorizontal(24);
      @include paddingVertical(16);
      &:hover {
        background-color: $color-gray50;
      }
      p {
        padding-left: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.searchContainer {
  @include row();
  gap: 16px;
  @include for-size(mobile-portrait) {
    justify-content: space-between;
  }
  @include for-size(mobile-landscape) {
    justify-content: space-between;
  }
  @include for-size(tablet-portrait) {
  }
  @include for-size(tablet-landscape) {
  }
  @include for-size(desktop) {
  }
  .searchinput {
    @include row();
    @include for-size(mobile-portrait) {
      width: 100%;
    }
    @include for-size(mobile-landscape) {
    }
    @include for-size(tablet-portrait) {
    }
    @include for-size(tablet-landscape) {
    }
    @include for-size(desktop) {
    }
    min-width: 100%;
    padding-left: 8px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background-color: $color-white;
    svg {
      align-self: center;
      height: 32px;
      width: 32px;
      fill: $color-gray200;
      @include paddingHorizontal(8);
    }
    input {
      flex: 1;
      @include paddingHorizontal(16);
      @include paddingVertical(12);
      @include normal();
      border: none;
      outline: none;
      width: 100%;
    }
    form {
      width: 100%;
    }
  }
  .filterButton {
    @include paddingHorizontal(8);
    @include for-size(tablet-portrait) {
      display: none;
    }

    @include for-size(tablet-landscape) {
      display: none;
    }
    @include for-size(desktop) {
      display: none;
    }

    @include row();
    align-items: center;
    background-color: $color-transparent;
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 8px;
    &:hover {
      background-color: $color-secondary;
    }
    p {
      @include strong();
      text-transform: uppercase;
      color: $color-primary;
      @include for-size(mobile-portrait) {
        display: none;
      }
      @include for-size(mobile-landscape) {
        display: none;
      }
    }
    svg {
      @include marginHorizontal(8);
      fill: $color-primary;
      height: 24px;
      width: 24px;
    }
  }
}
