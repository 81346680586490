@import "@styles/theme";

.root {
  box-shadow: 0px 20px 24px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;
  @include row();
  position: absolute;
  padding-bottom: 16px;
  top: 86px;
  max-height: 50vh;
  width: 100%;
  left: 0;
  background-color: $color-white;
  &.hide {
    opacity: 0;
    display: none;
    pointer-events: none;
  }
  &.show {
    top: 100%;
    -webkit-animation: fadeIn 0.4s;
    -moz-animation: fadeIn 0.4s;
    -o-animation: fadeIn 0.4s;
    animation: fadeIn 0.4s;
    transition: top 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s;
  }
  .left {
    @include row();
    flex: 6;
    @include paddingVertical(32);
    justify-content: space-around;
    .wrapper {
      @include column();
      flex-basis: 32%;
      min-height: 35vh;
    }
  }
  .right {
    flex: 4;
    padding: 4px;
    @include column();
    justify-content: space-around;
    align-items: flex-start;
    .familiesContainer {
      @include column();
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      max-height: 250px;
      .family {
        border-radius: 4px;
        text-decoration: none;
        color: $color-black;
        p {
          @include row();
          width: 100%;
          align-items: center;
          padding-left: 8px;
        }
        &:hover {
          background-color: $color-secondary;
        }
        @include paddingVertical(8);
        @include paddingHorizontal(8);
        @include marginVertical(2);
      }
    }
    .todosLink {
      color: $color-primary;
      p {
        font-weight: bold;
      }
      border: 1px solid $color-primary;
      text-decoration: none;
      @include paddingVertical(4);
      @include paddingHorizontal(64);
      &:hover {
        background-color: $color-secondary;
        text-decoration: underline;
      }
    }
  }
  .divider {
    width: 2px;
    height: 90%;
    background-color: $color-gray100;
    @include marginHorizontal(12);
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
