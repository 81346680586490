@import "@styles/theme";

.root {
  @include column();
  .tabswitcher {
    @include row();
    @include paddingHorizontal(64);
    justify-content: flex-start;
    border-bottom: 1px solid $color-gray100;
    @include for-size(mobile-portrait) {
      justify-content: center;
      @include paddingHorizontal(0);
    }
    @include for-size(mobile-landscape) {
      justify-content: center;
      @include paddingHorizontal(0);
    }
    @include for-size(tablet-portrait) {
      justify-content: center;
      @include paddingHorizontal(0);
    }

    .tab {
      @include row();
      justify-content: flex-start;
      align-items: center;
      margin-right: 64px;
      padding: 8px;
      background-color: $color-white;
      border: none;
      border-bottom: 2px solid $color-transparent;
      @include for-size(mobile-portrait) {
        @include column();
        @include marginHorizontal(4);
        @include paddingHorizontal(0);
        min-width: calc(30% - 8px);
      }
      @include for-size(mobile-landscape) {
        @include column();
        @include marginHorizontal(16);
        @include paddingHorizontal(0);
        min-width: calc(30% - 32px);
      }
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
      &.active {
        border-bottom: 2px solid $color-primary;
      }
      img {
        height: 32px;
        width: 32px;
        @include marginHorizontal(8);
      }
    }
  }
  .dataContainer {
    align-self: center;
    @include grid();
    grid-template-columns: 1fr 1fr;
    align-items: center;
    @include paddingVertical(8);
    width: calc(100% - 64px);
    @include for-size(mobile-portrait) {
      grid-template-columns: 1fr;
    }
    @include for-size(mobile-landscape) {
      @include paddingHorizontal(8);
      grid-template-columns: 1fr;
    }
    .data {
      @include paddingVertical(16);
      @include column();
      flex: 1;
      .topheader {
        @include row();
        @include marginVertical(8);
        align-items: center;
        @include for-size(mobile-portrait) {
          justify-content: center;
        }
        @include for-size(mobile-landscape) {
          justify-content: center;
        }
        h4 {
          margin-left: 16px;
        }
        img {
          height: 64px;
          width: 64px;
          @include marginHorizontal(8);
        }
      }
      .info {
        margin-top: 32px;
        @include column();
        @include for-size(mobile-portrait) {
          align-items: center;
        }

        @include for-size(mobile-landscape) {
          align-items: center;
        }
        p {
          max-width: 420px;
          margin-bottom: 16px;
          @include paddingHorizontal(16);
        }
      }
    }
    .slider {
      @include for-size(mobile-portrait) {
        max-width: 100%;
      }
      @include for-size(mobile-landscape) {
        max-width: 100%;
      }
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .productsContainer {
    @include paddingHorizontal(128);
    @include for-size(mobile-portrait) {
      @include paddingHorizontal(8);
    }
    @include for-size(mobile-landscape) {
      @include paddingHorizontal(16);
    }
    @include for-size(tablet-portrait) {
      @include paddingHorizontal(32);
    }
    @include for-size(tablet-landscape) {
      @include paddingHorizontal(64);
    }
    .title {
      margin-top: 16px;
      text-align: center;
    }
    .productsList {
      @include row();
      justify-content: center;
      margin-top: 16px;
      gap: 16px;
      flex-wrap: nowrap;
      @include for-size(mobile-portrait) {
        flex-wrap: wrap;
      }
      @include for-size(mobile-landscape) {
        flex-wrap: wrap;
      }
      .productWrapper {
        height: 430px;
        @include for-size(mobile-portrait) {
          @include marginVertical(4);
          max-width: 100%;
        }
        @include for-size(mobile-landscape) {
          @include marginHorizontal(8);
          max-width: 320px;
        }
        @include for-size(tablet-portrait) {
          @include marginHorizontal(8);
        }
        @include for-size(tablet-landscape) {
          @include marginHorizontal(8);
        }
        @include marginVertical(8);
      }
    }
  }
}
