@import "@styles/theme";

.root {
  transition: top 0.5s cubic-bezier(0.5, 1, 0.89, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 4px;
  background-color: $color-white;
  position: fixed;
  z-index: 1001;
  width: 100%;
  .bar {
    padding: 0 4.8rem;
    @include for-size(mobile-portrait) {
      padding: 0 $spacing-md;
    }
    @include for-size(mobile-landscape) {
      padding: 0 $spacing-md;
    }
    @include for-size(tablet-portrait) {
      padding: 0 $spacing-md;
    }

    display: flex;
    flex-direction: row;
    .banner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        height: 48px;
        width: auto;
      }
    }
  }
  .navData {
    .topRow {
      display: flex;
      flex-direction: row;
      padding-bottom: 16px;
      justify-content: flex-end;
      align-items: center;
      .contactanos {
        margin: 0 $spacing-md;
        font-family: $font-family-body;
        color: $color-black;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
    flex-grow: 1;
    .links {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      a {
        text-overflow: ellipsis;

        font-family: $font-family-headings;
        padding-bottom: 16px;
        border-bottom: 2px solid $color-transparent;
        &:hover {
          border-bottom-color: $color-primary;
        }

        padding: 0 $spacing-md;
        color: $color-black;
        align-items: center;
        text-decoration: none;
        display: flex;
        flex-direction: row;
      }
    }
  }

  .mobileNavData {
    position: relative;
    flex: 1;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .burgerButton {
      background-color: $color-transparent;
      border: none;
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}
.mobileMenu {
  background-color: $color-white;

  position: absolute;
  //top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  &.show {
    top: 0;
    transition: top 800ms cubic-bezier(0.37, 0, 0.63, 1) 0s;
  }
  &.hide {
    top: -200vh;
    transition: top 800ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 3ms;
  }
  svg {
    height: 40px;
    width: 40px;
  }
  a {
    font-family: $font-family-headings;

    text-align: center;
    text-decoration: none;
    color: $color-black;
    padding: $spacing-md 0;
  }
  .languages {
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: $spacing-md 0;
    @include for-size(mobile-portrait) {
      justify-content: flex-end;
      top: 0;
      right: 0;
      position: relative;
    }
  }
  .closebutton {
    background-color: $color-transparent;
    border: none;
    svg {
      fill: $color-primary;
    }
  }
}
