@import "@styles/theme";

.root {
  @include row();
  justify-content: center;
  @include paddingVertical(64);
  background-color: $color-gray50;
  background-position-x: 80%;
  background-position-y: center;
  @include for-size(mobile-portrait) {
    background-position-x: center;
    background-position-y: bottom;
  }
  @include for-size(mobile-landscape) {
    background-position-x: center;
    background-position-y: bottom;
  }
  @include for-size(tablet-portrait) {
    background-position-x: center;
    background-position-y: bottom;
  }
  @include for-size(mobile-portrait) {
  }
  @include for-size(mobile-landscape) {
  }
  @include for-size(tablet-portrait) {
    background-position-x: 90%;
  }
  @include for-size(tablet-landscape) {
  }

  background-size: contain;
  background-repeat: no-repeat;
  .manvert,
  .news {
    @include marginVertical(8);
    line-height: 2rem;
  }

  a {
    @include row();
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    text-decoration: none;
    .info {
      flex: 1;
      @include column();
      justify-content: center;
      min-width: 240px;
      width: 100%;
      max-width: 360px;
      position: relative;
      @include paddingHorizontal(32);

      @include for-size(mobile-landscape) {
        width: 100%;
        max-width: 612px;
        text-align: center;
      }

      @include for-size(mobile-portrait) {
        text-align: center;
      }
      svg {
        position: absolute;
        top: -24px;
        left: -24px;
        height: 48px;
        width: 48px;
        transform: rotate(37deg);
        @include for-size(mobile-landscape) {
          top: -12px;
          left: 12px;
        }

        @include for-size(mobile-portrait) {
          top: -12px;
          left: 12px;
        }
      }
      .manvert {
        color: $color-black;
        font-size: 3.4rem;

      }
      .news {
        color: $color-primary;
        font-size: 3.4rem;

      }
      p {
        margin-top: 10px;
        color: $color-black;
      }
    }
    .linkwrapper {
      @include column();
      @include paddingHorizontal(32);
      align-items: center;
      @include marginVertical(16);

      img {
        margin-top: 1rem;
        height: 64px;
        width: auto;
      }
    }
  }
}
