@import "@styles/theme";

.root {
  @include row();
  max-width: 920px;
  flex-wrap: wrap;
  margin: 0 auto;
  @include paddingVertical(32);
  gap: 16px;
  justify-content: center;
}
