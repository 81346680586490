@import "@styles/theme";

.root {
  position: relative;
  @include row();
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  @include for-size(mobile-portrait) {
    @include column();
    width: 95%;
  }
  @include for-size(mobile-landscape) {
    @include column();
    width: 90%;
  }
  @include for-size(tablet-portrait) {
    width: 100%;
  }
  @include for-size(tablet-landscape) {
  }
  @include for-size(desktop) {
    max-width: 1220px;
  }
  .dropdownwrapper {
    height: 72px;
    position: relative;
    min-width: 180px;
    @include row();
    border: 2px solid $color-gray50;
    background-color: $color-white;
    max-width: 240px;
    @include for-size(mobile-portrait) {
      min-width: 100%;
    }
    @include for-size(mobile-landscape) {
      min-width: 100%;
    }
    @include for-size(tablet-portrait) {
      flex: 1;
    }
    @include for-size(tablet-landscape) {
      min-width: 240px;
    }
    @include for-size(desktop) {
      min-width: 240px;
    }
    .headerWrapper {
      @include row();
      background: none;
      outline: none;
      border: none;
      width: 100%;
      align-items: center;
      &:hover {
        cursor: pointer;
      }

      .value {
        flex: 1;
        @include paddingHorizontal(8);
        .title {
          text-align: left;
          opacity: 0.7;
          text-transform: uppercase;
        }
        .labelContainer {
          @include row();
          .label {
            flex: 1;
            max-width: 160px;
            font-family: $font-family-body;
            @include for-size(mobile-portrait) {
              max-width: none;
            }
            @include for-size(mobile-landscape) {
              max-width: none;
            }
            @include for-size(tablet-portrait) {
            }
            @include for-size(tablet-landscape) {
            }
            @include for-size(desktop) {
            }
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
          .toggleIcon {
            fill: $color-primary;
            @include paddingHorizontal(4);
            height: 12px;
            align-self: center;
          }
        }
      }
    }
    .list {
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
      z-index: 100;
      cursor: pointer;
      position: absolute;
      list-style: none;
      max-height: 420px;
      overflow-y: scroll;
      margin-top: 8px;
      left: 0;
      top: 62px;
      width: calc(100%);
      background-color: $color-white;
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
      li {
        @include row();
        img {
          height: 24px;
          width: 24px;
          margin-right: 16px;
          fill: $color-primary;
        }
        @include paddingHorizontal(16);
        @include paddingVertical(16);
        text-align: left;
        p {
          @include normal();
        }
        &:hover {
          background-color: $color-secondary;
        }
      }
    }
  }
  .searchbutton {
    border: 2px solid $color-gray50;
    min-height: 72px;
    @include row();
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    color: $color-white;
    text-decoration: none;

    @include paddingHorizontal(32);
    @include for-size(mobile-portrait) {
      min-width: 0px;
      flex: 1;
    }
    @include for-size(mobile-landscape) {
      min-width: 0px;
      flex: 1;
    }
    @include for-size(tablet-portrait) {
      @include paddingHorizontal(16);
    }
    @include for-size(tablet-landscape) {
      min-width: 172px;
    }

    svg {
      height: 16px;
      width: 16px;
      fill: $color-white;
    }
    p {
      @include paddingHorizontal(8);
    }
  }
}
