@import "@styles/theme";

.root {
  @include column();
  justify-content: flex-end;
  align-items: center;
  @include bounceTop();
  padding: 1rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  h4 {
    @include marginVertical(16);
    text-align: center;
    color: $color-black;
  }
  .imageWrapper {
    flex: 1;
    @include column();
    justify-content: center;
    img {
      height: auto;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
