@import "@styles/theme";

.root {
  position: relative;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
  background-color: $color-white;
  padding: 8px;
  width: 100%;
  max-width: 300px;
  @include row();
  margin: 1px;
  .headerWrapper {
    @include row();
    background: none;
    outline: none;
    border: none;
    width: 100%;
    align-items: center;
    &:hover {
      cursor: pointer;
    }

    .value {
      @include paddingHorizontal(8);
      flex: 1;
      .title {
        @include paddingVertical(2);
        text-align: left;
        color: $color-black;
        opacity: 0.7;
        text-transform: uppercase;
      }
      .label {
        @include row();

        font-family: $font-family-body;
        @include normal();
      }
    }
    .toggleIcon {
      @include row();

      align-items: center;
      svg {
        @include marginHorizontal(8);
        fill: $color-primary;
        height: 18px;
        width: 18px;
      }
    }
  }
  .list {
    cursor: pointer;
    position: absolute;
    list-style: none;
    left: 0;
    top: 62px;
    width: calc(100%);
    background-color: $color-white;
    max-height: 240px;
    overflow-y: scroll;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    li {
      @include paddingHorizontal(24);
      @include paddingVertical(15);
      min-width: 240px;
      text-align: left;
      &:hover {
        background-color: $color-secondary;
      }
    }
  }
}
