@import '@styles/theme';

.root {
  display: flex;
  flex-direction: column;

  padding: 2rem 15rem;
  @include for-size(mobile-portrait) {
    padding: 2rem 1.6rem;
  }
  @include for-size(mobile-landscape) {
    padding: 2rem 3.2rem;
  }
  @include for-size(tablet-portrait) {
    padding: 2rem 6.4rem;
  }
  @include for-size(tablet-landscape) {
    padding: 2rem 6.4rem;
  }

  .data {
    display: flex;
    flex-direction: row;
    @include for-size(mobile-portrait) {
      flex-direction: column;
    }
    flex-wrap: wrap;
    .info {
      flex-grow: 1;
      align-items: flex-start;
      @include for-size(mobile-portrait) {
        align-items: center;
      }
      @include for-size(mobile-landscape) {
        align-items: center;
      }
      text-decoration: none;
      color: $color-black;
      display: flex;
      flex-direction: column;
      min-width: 170px;
      svg {
        margin-bottom: 8px;
        height: 48px;
        width: auto;
      }
      p {
        letter-spacing: 1px;
      }
    }
    .urls {
      padding: $spacing-md 0;
      display: flex;
      flex-direction: row;
      @include for-size(mobile-portrait) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      @include for-size(mobile-landscape) {
        align-items: center;
        text-align: center;
      }
      flex-grow: 1;
      max-width: 750px;
      flex-wrap: wrap;
      a {
        margin: $spacing-md 0;
        min-width: 24%;
        color: $color-black;
        text-decoration: none;
      }
    }
  }

  .languageAndCountry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    @include for-size(mobile-portrait) {
      flex-direction: column;
      width: 80%;
    }
  }
  .bottomRow {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    flex-wrap: wrap;
    justify-content: center;
    .politica {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      @include for-size(mobile-portrait) {
        // min-width: 60%;
        margin-bottom: 16px;
        width: 100%;
        flex: none;
      }
      @include for-size(mobile-landscape) {
        // min-width: 50%;
        margin-bottom: 16px;
        width: 100%;
        flex: none;
      }
      flex: 1;

      a {
        width: calc(100% - 20px);
        color: $color-black;
        text-transform: uppercase;
        p {
          @include for-size(mobile-portrait) {
            text-align: center;
          }
          @include for-size(mobile-landscape) {
            text-align: center;
          }
          @include for-size(tablet-portrait) {
            text-align: left;
          }
        }
      }
    }
    .social {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      @include for-size(mobile-portrait) {
        min-width: 60%;
        flex: 1;
        justify-content: center;
      }
      @include for-size(mobile-landscape) {
        min-width: 60%;
        flex: 1;
        justify-content: center;
      }
      flex: 1;
      a {
        padding: 0 $spacing-md;
        img {
          height: 28px;
          width: 28px;
        }
      }
    }
  }
}

.paises {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75%;

  padding: $spacing-md 0;
  @include for-size(mobile-portrait) {
    width: 100%;
    justify-content: flex-end;
    top: 0;
    right: 0;
    position: relative;
  }
}

.languages {
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 14px;
  padding-bottom: 14px;
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 12%);
  width: 20%;
  border-radius: 4px;

  @include for-size(mobile-portrait) {
    padding-top: 14px;
    padding-bottom: 14px;
    z-index: 1;
    justify-content: flex-end;
    top: 0;
    right: 0;
    position: relative;
    width: 100%;
  }
  @include for-size(mobile-landscape) {
    width: auto;
    margin-right: 10px;
  }
}
