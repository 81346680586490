@import "@styles/theme";

.root {
  @include grid();
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto;
  gap: 16px;
  @include marginVertical(16);
  @include marginHorizontal(16);
  @include for-size(mobile-portrait) {
    grid-template-columns: 1fr;
  }
  @include for-size(mobile-landscape) {
    grid-template-columns: 1fr 1fr;
  }
}
