@import "@styles/theme";

.root {
  &.animated {
    transition: transform 0.3s ease-out;
    &:hover {
      transform: translate(0, -15px);
    }
  }
  @include row();
  width: 250px;
  height: 175px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  .borderFill {
    width: 3px;
  }
  .info {
    flex-grow: 1;
    @include column();
    justify-content: center;
    padding: 24px;
    .header {
      margin-bottom: 10px;
    }
    .description {
    }
  }
}
