@import "@styles/theme";

.root {
  @include paddingHorizontal(48);
  @include row();
  align-items: center;
  .item {
    padding-right: 8px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $font-family-body;
    text-align: left;
    @include micro();
    color: $color-black;
    &.disabled {
      color: $color-gray200;
    }
  }
}
