@import "@styles/theme";

.root {
  @include column();
  z-index: 1;
  background-color: $color-white;
  text-decoration: none;
  width: calc(100%);
  height: calc(100%);
  .imagecontainer {
    @include column();
    height: 70%;
    justify-content: center;
    align-items: center;
    position: relative;
    .imagewrapper {
      position: relative;
      @include column();
      height: 100%;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: auto;
        max-width: 90%;
        object-fit: contain;
        object-position: center;
      }
    }
    .eco {
      z-index: 2;
      position: absolute;
      top: 0;
      right: 0;
      height: 48px;
      width: 52px;
    }
  }
  .data {
    @include column();
    height: calc(30%);
    color: $color-black;
    .category {
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .productlink {
      @include row();
      margin-top: 16px;
      align-items: center;
      color: $color-primary;
      letter-spacing: 1px;
      &:hover {
        text-decoration: underline;
      }
      svg {
        height: 18px;
      }
    }
  }
  &.animated {
    transition: transform 0.3s ease-out;
    &:hover {
      transform: translate(0, -15px);
    }
  }
  &.card {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  }
}
