@import "@styles/theme";

.root_layout {
  .content {
    padding-top: $navbar-desktop-height;
    @include for-size(mobile-portrait) {
      padding-top: $navbar-mobile-height;
    }
    @include for-size(mobile-landscape) {
      padding-top: $navbar-mobile-height;
    }
    @include for-size(tablet-portrait) {
      padding-top: $navbar-mobile-height;
    }
    @include for-size(tablet-landscape) {
      padding-top: $navbar-desktop-height;
    }
    @include for-size(desktop) {
      padding-top: $navbar-desktop-height;
    }
  }
}
