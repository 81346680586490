@import "@styles/theme";

.root {
  position: relative;
  @include row();
  width: 100%;
  align-items: center;
  background-color: $color-white;
  border-radius: 4px;

  @include for-size(mobile-portrait) {
    min-width: 300px;
  }
  @include for-size(mobile-landscape) {
  }
  @include for-size(tablet-portrait) {
  }
  @include for-size(tablet-landscape) {
  }
  &.card {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
  }
  .headerWrapper {
    @include paddingHorizontal(12);
    @include paddingVertical(14);
    width: 100%;
    background: none;
    outline: none;
    border: none;
    @include row();
    justify-content: flex-end;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .value {
      @include paddingHorizontal(12);
      .title {
        color: $color-black;
        opacity: 0.7;
      }
      .label {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .toggleIcon {
      align-self: center;
      @include paddingHorizontal(8);
      svg {
        height: 16px;
        fill: $color-primary;
      }
    }
  }
  .list {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    border-radius: 4px;
    width: 100%;
    max-width: 350px;
    list-style: none;
    max-height: 400px;
    margin-top: 10px;
    overflow-y: scroll;
    top: 40px;
    right: 0;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    background-color: $color-white;
    li {
      @include paddingHorizontal(24);
      @include paddingVertical(16);
      &:hover {
        background-color: $color-gray50;
      }
      p {
        padding-left: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
