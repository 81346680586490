@import "@styles/theme";

.root {
  width: 280px;
  height: 375px;
  position: relative;
  transition: transform 0.3s ease-out;
  background-position: center;
  background-size: cover;
  &:hover {
    transform: translate(0, -15px);
  }
  .card {
    position: absolute;
    min-height: 128px;
    @include paddingHorizontal(16);
    padding-bottom: 16px;
    padding-top: 32px;
    bottom: 0;
    left: 0;
    right: 10%;
    @include column();
    background-color: $color-white;
    justify-content: space-between;
    h4 {
      color: $color-black;
    }
    .date {
      @include small();
      margin-bottom: 16px;
      font-weight: 700;
      color: $color-gray800;
    }

    .link {
      margin-top: 1rem;
      @include small();
      @include row();
      align-items: center;
      color: $color-primary;
      font-weight: 600;
      svg {
        margin-left: 4px;
        height: 22px;
        width: auto;
      }
    }
  }
}
