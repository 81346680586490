@import "@styles/theme";

.root {
  @include grid();
  @include paddingVertical(32);
  grid-template-columns: 1fr 1fr 1fr;
  @include paddingHorizontal(16);
  margin: 0 auto;
  gap: 8px;
  @include for-size(mobile-portrait) {
    grid-template-columns: 1fr;
    max-width: 90vw;
  }
  @include for-size(mobile-landscape) {
    grid-template-columns: 1fr 1fr;
  }
  @include for-size(tablet-portrait) {
    grid-template-columns: 1fr 1fr;
  }
  @include for-size(tablet-landscape) {
  }
}
