@import "@styles/theme";

.root {
  padding: 2rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-left: 3px solid $color-primary;
  background-color: $color-white;
  @include marginVertical(16);
  h4 {
    @include for-size(mobile-portrait) {
      text-align: center;
    }
  }
  .combinacion {
    @include paddingVertical(12);
    @include row();
    @include for-size(mobile-portrait) {
      @include column();
    }
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    .prod {
      @include row();
      align-items: center;
    }
    a {
      min-width: 120px;
      @include strong();
      font-family: $font-family-headings;
      color: $color-bioestimulantes;
    }
    .plus {
      @include marginHorizontal(8);
      font-weight: bold;
    }
  }
}
