@import "@styles/theme";
.root {
  position: relative;

  @include for-size(mobile-portrait) {
    max-width: none;
    flex: 1;
    @include row();
    justify-content: flex-end;
  }
  @include for-size(mobile-landscape) {
    max-width: none;
    flex: 1;
    @include row();
    justify-content: flex-end;
  }
  @include for-size(tablet-portrait) {
    max-width: none;
    flex: 1;
    @include row();
    justify-content: flex-end;
  }
  @include for-size(tablet-landscape) {
  }
  button {
    &:focus {
      outline: none;
    }
    @include row();
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    //@include marginHorizontal(32);
    background-color: $color-transparent;
    border: none;
    font-family: $font-family-body;
    @include for-size(mobile-portrait) {
      @include paddingHorizontal(12);
    }
    @include for-size(mobile-landscape) {
    }
    @include for-size(tablet-portrait) {
    }
    @include for-size(tablet-landscape) {
      @include micro();
    }
    @include micro();
    letter-spacing: 0px;
    color: $color-black;
    text-transform: capitalize;
    .value {
      @include for-size(mobile-portrait) {
        @include paddingHorizontal(12);
        font-weight: bold;
      }
    }
    .toggleIcon {
      align-self: center;
      @include paddingHorizontal(8);
      svg {
        height: 16px;
        fill: $color-primary;
      }
    }
  }
  ul {
    background-color: $color-white;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
    position: absolute;
    cursor: pointer;
    border-radius: 4px;

    list-style: none;
    top: 34px;

    @include for-size(tablet-landscape) {
    }
    li {
      @include for-size(mobile-portrait) {
        @include paddingHorizontal(16);
        @include paddingVertical(16);
      }
      @include for-size(mobile-landscape) {
        @include paddingHorizontal(16);
        @include paddingVertical(16);
      }
      @include for-size(tablet-portrait) {
        @include paddingHorizontal(16);
        @include paddingVertical(16);
      }
      @include for-size(tablet-landscape) {
        @include paddingHorizontal(8);
        @include paddingVertical(8);
      }

      @include paddingVertical(8);

      text-transform: capitalize;
      text-align: center;
      &:hover {
        background-color: $color-gray50;
      }
      &.divider {
        border-top-width: 1px;
        border-top-color: $color-gray100;
        border-top-style: solid;
      }
    }
  }
}
