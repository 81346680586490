@import "@styles/theme";

.root {
  @include column();
  width: 100%;
  height: 420px;
  position: relative;
  cursor: pointer;
  @include bounceTop();
  .bgimage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .card {
    position: absolute;
    @include column();
    @include paddingHorizontal(16);
    padding-bottom: 16px;
    padding-top: 32px;
    height: calc(50%);
    min-height: 128px;
    bottom: 0;
    left: 0;
    right: 10%;
    background-color: $color-white;
    justify-content: space-between;
    h4 {
      color: $color-black;
    }
    .date {
      @include small();
      font-weight: 700;
      color: $color-gray800;
    }

    .link {
      @include small();
      @include paddingHorizontal(32);
      @include paddingVertical(8);
      align-self: flex-start;
      color: $color-white;
      background-color: $color-primary;
      font-weight: 600;
    }
  }
}
