@import "@styles/theme";

.root {
  height: calc(100% + 40px);
  @include for-size(mobile-portrait) {
    height: calc(100%);
  }
  @include for-size(mobile-landscape) {
    height: calc(100%);
  }

  flex: 1;
  @include column();
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  .card {
    box-shadow: 0px 4px 24px rgba(190, 43, 43, 0.12);
  }
  .title {
    flex: 1;
    @include column();
    justify-content: center;
    h1 {
      @include paddingVertical(32);
      @include for-size(desktop) {
        @include paddingVertical(8);
      }
      color: $color-white;
      padding-left: 16px;
    }
  }
  .description {
    @include paddingHorizontal(32);
    @include paddingVertical(16);
    width: calc(90% - 64px);
    background-color: $color-white;
    @include for-size(mobile-portrait) {
      width: calc(100% - 32px);
      align-self: center;
      @include paddingHorizontal(16);
    }
    @include for-size(mobile-landscape) {
      @include paddingHorizontal(8);
      @include paddingVertical(16);
      width: calc(90% - 16px);
    }
    @include for-size(tablet-portrait) {
      @include paddingHorizontal(16);
      @include paddingVertical(16);
      width: calc(90% - 32px);
    }
    @include for-size(tablet-landscape) {
      @include paddingHorizontal(12);
      @include paddingVertical(16);
      width: calc(90% - 24px);
    }
    p {
      @include small();
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
